import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import SignTemplate from '@/components/project/templates/SignTemplate.vue'
import Signin from '@/views/Signin.vue'
import Signup from '@/views/Signup.vue'
import Maintenance from '@/components/project/globals/Maintenance.vue'

export default createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes: [
    {
      path: '',
      component: SignTemplate,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          next()
        }
      },
      children: [
        {
          path: '',
          redirect: 'signin'
        },
        {
          path: 'signin',
          name: 'signin',
          component: Signin
        },
        {
          path: 'twofaconfig',
          component: () =>
            import(
              /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaConfig.vue'
            ),
          children: [
            {
              path: '',
              name: 'twofaactivation',
              component: () =>
                import(
                  /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaActivation.vue'
                )
            },
            {
              path: 'twofaphone',
              name: 'twofaphone',
              component: () =>
                import(
                  /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaPhone.vue'
                )
            },
            {
              path: 'twofaapp',
              name: 'twofaapp',
              component: () =>
                import(
                  /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaApp.vue'
                )
            },
            {
              path: 'twofaphonecode',
              name: 'twofaphonecode',
              component: () =>
                import(
                  /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaPhoneCode.vue'
                )
            },
            {
              path: 'twofaappqrcode',
              name: 'twofaappqrcode',
              component: () =>
                import(
                  /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaAppQrCode.vue'
                )
            },
            {
              path: 'twofaappcode',
              name: 'twofaappcode',
              component: () =>
                import(
                  /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaAppCode.vue'
                )
            },
            {
              path: 'twofaphonesuccess',
              name: 'twofaphonesuccess',
              component: () =>
                import(
                  /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaPhoneSuccess.vue'
                )
            },
            {
              path: 'twofaappsuccess',
              name: 'twofaappsuccess',
              component: () =>
                import(
                  /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaAppSuccess.vue'
                )
            },
            {
              path: 'twofasuccess',
              name: 'twofasuccess',
              component: () =>
                import(
                  /* webpackChunkName: "TfaConfig" */ '@/views/TwoFaConfig/TwoFaSuccess.vue'
                  )
            }
          ]
        },
        {
          path: 'twofalogin',
          component: () =>
            import(
              /* webpackChunkName: "TfaLogin" */ '@/views/TwoFaLogin/TwoFaLogin.vue'
            ),
          beforeEnter: (_to, _from, next) => {
            if (store.state.user.email) next()
            else next({ name: 'signin' })
          },
          children: [
            {
              path: '',
              name: 'twofaloginappcode',
              component: () =>
                import(
                  /* webpackChunkName: "TfaLogin" */ '@/views/TwoFaLogin/TwoFaLoginAppCode.vue'
                )
            },
            {
              path: 'twofaloginphonecode',
              name: 'twofaloginphonecode',
              component: () =>
                import(
                  /* webpackChunkName: "TfaLogin" */ '@/views/TwoFaLogin/TwoFaLoginPhoneCode.vue'
                )
            },
            {
              path: 'twofaloginapphelp',
              name: 'twofaloginapphelp',
              component: () =>
                import(
                  /* webpackChunkName: "TfaLogin" */ '@/views/TwoFaLogin/TwoFaLoginAppHelp.vue'
                )
            },
            {
              path: 'twofaloginphonehelp',
              name: 'twofaloginphonehelp',
              component: () =>
                import(
                  /* webpackChunkName: "TfaLogin" */ '@/views/TwoFaLogin/TwoFaLoginPhoneHelp.vue'
                )
            }
          ]
        },
        {
          path: 'twofareset',
          component: () =>
            import(
              /* webpackChunkName: "TfaLogin" */ '@/views/TwoFaReset/TwoFaReset.vue'
            ),
          beforeEnter: (_to, _from, next) => {
            if (store.state.user.email) {
              next()
            } else {
              next({ name: 'signin' })
            }
          },
          children: [
            {
              path: '',
              name: 'twofaresetcode',
              component: () =>
                import(
                  /* webpackChunkName: "TfaLogin" */ '@/views/TwoFaReset/TwoFaResetCode.vue'
                )
            },
            {
              path: 'twofaresetsac',
              name: 'twofaresetsac',
              component: () =>
                import(
                  /* webpackChunkName: "TfaLogin" */ '@/views/TwoFaReset/TwoFaResetSac.vue'
                )
            }
          ]
        },
        {
          path: 'signup',
          name: 'signup',
          component: Signup,
          beforeEnter: (to, from, next) => {
            next({ name: 'signinemail' })
          }
        },
        {
          path: 'feedback',
          name: 'feedback',
          component: () =>
            import(/* webpackChunkName: "feedback" */ '@/views/Feedback.vue')
        },
        {
          path: 'reset-password',
          name: 'resetpassword',
          component: () =>
            import(
              /* webpackChunkName: "resetpassword" */ '@/views/ResetPassword.vue'
            )
        },
        {
          path: 'validate',
          name: 'validate',
          component: () =>
            import(/* webpackChunkName: "validate" */ '@/views/Validate.vue')
        }
      ]
    },
    {
      path: '/validated/:token',
      name: 'validatedUser',
      component: () =>
        import(
          /* webpackChunkName: "validated" */ '@/components/project/templates/ValidateTemplate.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          next()
        }
      },
      children: [
        {
          path: 'set-password',
          name: 'setpassword',
          component: () =>
            import(
              /* webpackChunkName: "setpassword" */ '@/views/Validated/SetPassword.vue'
            )
        },
        {
          path: 'profile',
          name: 'profile',
          component: () =>
            import(
              /* webpackChunkName: "profile" */ '@/views/Validated/Profile.vue'
            )
        }
      ]
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next()
        } else {
          next({ name: '' })
        }
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: 'signin'
    }
  ]
})
