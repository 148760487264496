<template>
  <div
    class="el-chunky eu-pa_5 emd-modal"
    :class="{ _active: isActive }"
  >
    <div class="emd-modal__content">
      <div class="emd-container _footer_centered">
        <div class="emd-container__content">
          <h2
            class="emd-text _size_section _color_contrast _align_center eu-mt_2"
          >
            {{ $t('TWOFA-RESET-SUCCESS.TITLE') }}
          </h2>
          <p class="emd-text _color_default _align_center eu-mt_5 eu-px_5">
            {{ $t('TWOFA-RESET-SUCCESS.DESCRIPTION') }}
          </p>
        </div>
        <div class="emd-container__footer el-chunky eu-pa_5">
          <button
            :disabled="isLoading"
            @click="login"
            class="emd-btn _color_primary eu-grow_1"
          >
            <emd-loader
              v-if="isLoading"
              type="_subtle"
            ></emd-loader>
            <span v-else>{{ $t('TWOFA-RESET-SUCCESS.CONFIRM-BUTTON') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { env } from '@/store'
import { EmdLoader } from 'emerald-vue3/src/components'
import recaptchaV3 from '@/mixins/recaptchaV3.js'
import twofaRequired from '@/mixins/twofaRequired'
import { mapActions } from 'vuex'
import { AuthErrorHandler } from '@/support'

export default {
  name: 'TwoFaResetSuccess',
  components: {
    EmdLoader
  },
  mixins: [recaptchaV3, twofaRequired],
  computed: {
    isActive () {
      return this.$store.state.modal.resetSuccess
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['SET_REQUIRED_CAPTCHA_V2']),
    async login () {
      await this.SET_REQUIRED_CAPTCHA_V2(false)

      this.isLoading = true
      sessionStorage.removeItem(`${env}mp_si`)

      const body = this.$store.state.tfaLoginBody
      const captchaToken = await this.generateRecaptchav3()

      this.$store
        .dispatch('CREATE_AUTHORIZATION', {...body, captcha_token: captchaToken })
        .then(() => {
          this.$router.push({ name: 'twofaactivation' })
        })
        .catch((err) => {
          const authorizationErrorHandler = new AuthErrorHandler(err, body)
          const errorHandler = authorizationErrorHandler.getInstance()

          errorHandler.resolve()
        })
        .finally(() => {
          this.$store.commit('updateResetSuccess')
          this.isLoading = false
        })
    }
  }
}
</script>
<style scoped>
.emd-container .emd-container__footer .emd-btn {
  margin-right: 0;
}
</style>
